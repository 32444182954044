import './App.css';
import Header from './components/Header/Header.js';
import PostList from './components/Posts/PostList';
import { PostContextProvider } from './components/context/PostContext';
function App() {
    return (
        <PostContextProvider>
            <Header />
            <PostList />
        </PostContextProvider>
    );
}

export default App;
