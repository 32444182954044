import styles from './NewPost.Module.css';
import { useState, useContext } from 'react';
import { AiOutlineCamera } from 'react-icons/ai';
import generateRandomUsername from '../../helpers/randomUsernameGenerator';
import PostContext from '../context/PostContext';
import ProgressBar from '@badrap/bar-of-progress';

export default function NewPostDialog() {
    const { toggleNewPostDialog, setPosts, posts } = useContext(PostContext);
    const [imageToPost, setImageToPost] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [imageURL, setImageURL] = useState('');
    const progress = new ProgressBar({
        size: 3,
        color: '#ffffff',
    });

    // Toggle Image input field
    const toggleImageToPost = (e) => {
        e.preventDefault();
        setImageToPost((prev) => !prev);
    };

    // Create a POST
    const onCreatePostHandler = async (e) => {
        e.preventDefault();
        toggleNewPostDialog();
        progress.start();

        try {
            let username = await generateRandomUsername();
            let id = Math.floor(Math.random() * 1000);
            let post = {
                id: id,
                username,
                title,
                content,
                imageURL,
                likes: 0,
            };

            await fetch(`${process.env.REACT_APP_SERVER}/posts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(post),
            });

            setPosts([post, ...posts]);
        } catch (error) {
            console.log(error.message);
        }
        progress.finish();
    };

    return (
        <div className={styles.cardContainer}>
            <form onSubmit={onCreatePostHandler}>
                <div className={styles.dialogContainer}>
                    <input
                        type="text"
                        placeholder="Title"
                        className={styles.titleInput}
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        required
                    />
                    <textarea
                        type="text"
                        placeholder="What is happening?"
                        className={styles.contentInput}
                        onChange={(e) => setContent(e.target.value)}
                        value={content}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Paste Image URL here"
                        className={
                            imageToPost ? styles.imageInput : styles.hidden
                        }
                        onChange={(e) => setImageURL(e.target.value)}
                        value={imageURL}
                    />
                    <div className={styles.lastRowContainer}>
                        <button onClick={toggleImageToPost}>
                            <AiOutlineCamera className={styles.cameraIcon} />
                        </button>
                        <div className={styles.postBtnContainer}>
                            <button type="submit">Post</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
