import Post from './Post';
import styles from './PostList.Module.css';
import { useContext } from 'react';
import PostContext from '../context/PostContext';

export default function PostList() {
    const { posts, toggleNewPostDialog, newPostDialogState } =
        useContext(PostContext);
    // Handling the rendering of the List of all Posts
    return (
        <main
            onClick={newPostDialogState ? toggleNewPostDialog : () => {}}
            className={styles.postListContainer}
        >
            {posts.map(({ username, id, title, imageURL, content, likes }) => {
                return (
                    <Post
                        username={username}
                        id={id}
                        key={id}
                        imageURL={imageURL}
                        content={content}
                        likes={likes}
                        title={title}
                    />
                );
            })}
        </main>
    );
}
