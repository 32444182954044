import React, { useState, useEffect } from 'react';

// Context to manage entire Post State and the Active State of the form to create a post;
const PostContext = React.createContext({
    posts: [],
    setPosts: () => {},
    newPostDialogState: '',
    toggleNewPostDialog: () => {},
});

export function PostContextProvider(props) {
    const [posts, setPosts] = useState([]);
    const [newPostDialogState, setNewPostDialogState] = useState(false);

    const toggleNewPostDialog = () => {
        setNewPostDialogState((prevState) => !prevState);
    };

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER}/posts`);

                const jsonResponse = await response.json();

                setPosts(jsonResponse);
            } catch (error) {
                console.log(error.messsage);
            }
        };
        fetchPosts();
    }, []);

    return (
        <PostContext.Provider
            value={{
                posts: posts,
                setPosts: setPosts,
                newPostDialogState: newPostDialogState,
                toggleNewPostDialog: toggleNewPostDialog,
            }}
        >
            {props.children}
        </PostContext.Provider>
    );
}

export default PostContext;
