// Helper to generate a random username using randomuser.me API
const generateRandomUsername = async () => {
    try {
        let response = await fetch(
            'https://randomuser.me/api/?inc=name&nat=US',
        );

        let username;

        let jsonResponse = await response.json();

        let firstName = jsonResponse.results[0].name.first;

        username = firstName + Math.floor(Math.random() * 100);

        return username;
    } catch (error) {
        console.log(error.message);
    }
};

export default generateRandomUsername;
