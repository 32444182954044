import styles from './Header.Module.css';
import { useContext } from 'react';
import NewPostDialog from './NewPostDialog';
import PostContext from '../context/PostContext';

export default function Header() {
    const { newPostDialogState, toggleNewPostDialog } = useContext(PostContext);

    return (
        <>
            <header>
                <div className={styles.headerContainer}>
                    <p className={styles.appName}>CloudflareGram</p>
                    <div className={styles.btnContainer}>
                        <button
                            className={styles.newPostBtn}
                            onClick={toggleNewPostDialog}
                        >
                            New Post
                        </button>
                    </div>
                </div>
            </header>
            {newPostDialogState && <NewPostDialog />}
        </>
    );
}
