import styles from './Post.Module.css';
import { AiOutlineHeart } from 'react-icons/ai';
import { useState } from 'react';

export default function Post(props) {
    let { username, title, content, imageURL, likes, id } = props;
    const [likesCount, setLikesCount] = useState(likes);

    // Increment Likes on each Post
    const incrementLikes = async () => {
        try {
            setLikesCount((prevState) => prevState + 1);
            await fetch(
                `${process.env.REACT_APP_SERVER}/updatelikes?id=${id}`,
                {
                    method: 'PATCH',
                },
            );
        } catch (error) {
            setLikesCount((prevState) => prevState - 1);
            console.log(error.message);
        }
    };

    return (
        <div className={styles.postContainer}>
            <div className={styles.postHead}>
                <img
                    src={`https://avatars.dicebear.com/api/open-peeps/${username}.svg`}
                    alt={`${username}'s profile`}
                    className={styles.profilePicture}
                />
                <p className={styles.usernameText}>{username || 'Test123'}</p>
            </div>
            <div>
                <p className={styles.title}>{title || 'Lorem, ipsum dolor.'}</p>
                <p>
                    {content ||
                        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla nam ducimus excepturi quasi voluptas? Iure repellendus quae sit quibusdam dolore velit odio eum laudantium cumque!'}
                </p>
                {imageURL && (
                    <div className={styles.contentPictureContainer}>
                        <img
                            src={imageURL}
                            alt={`${username}'s content`}
                            className={styles.contentPicture}
                        />
                    </div>
                )}
            </div>
            <div className={styles.postFooter}>
                <button onClick={incrementLikes}>
                    <AiOutlineHeart className={styles.heart} />
                </button>
                <p>{`${likesCount || 0} likes`}</p>
            </div>
        </div>
    );
}
